<template>
  <div>
    <el-divider>{{ search.pay_at }}收支情况</el-divider>

    <el-row :gutter="16">
      <el-col :span="12" style="text-align: center;">
        <div class="statistic-card">
          <el-statistic :value="data.sum.in">
            <template #title>
              <div>收入 </div>
            </template>
          </el-statistic>
        </div>
      </el-col>
      <el-col :span="12" style="text-align: center;">
        <div class="statistic-card">
          <el-statistic :value="data.sum.out">
            <template #title>
              <div>支出</div>
            </template>
          </el-statistic>
        </div>
      </el-col>
    </el-row>

    <el-divider>统计视图</el-divider>

    <el-row :gutter="16">
      <el-col :span="24" :xs="24">
        <div id="budgetChart" style="width:100%;min-height:500px;"></div>
      </el-col>
    </el-row>

    <el-divider>收支明细</el-divider>
    <el-form :inline="true" class="search">
      <el-form-item label="收支类型">
        <el-select v-model="search.type" style="width:150px;" clearable>
          <el-option label="支出" :value="2"></el-option>
          <el-option label="收入" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getCosts()">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="pay_at" label="时间"/>
      <el-table-column prop="type_desc" label="类型">
        <template #default="scope">
          {{ scope.row.pay_type_desc + scope.row.type_desc }}
        </template>
      </el-table-column>
      <el-table-column prop="price" label="金额"/>
      <el-table-column prop="remark" label="备注"/>
      <el-table-column prop="contract.name" label="项目" />
      <el-table-column prop="budget.name" label="预算名" />
    </el-table>

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="search.page"
          @update:current-page="getCosts()"
          class="mt-4"
      />
    </div>
  </div>
</template>


<script>
import {apiGetProjectNumbersCount, apiGetProjectNumberCosts} from "@/api/projectNumbersApi"
import * as echarts from 'echarts'

export default {
  mounted() {
    this.getData()
    this.getCosts()
  },
  created() {
    this.search = this.$route.query

    if (!(this.search.company_id && this.search.pay_at && this.search.token)) {
      window.location.href = '/'
    }
  },
  data() {
    return {
      tableData: [],
      search:{
        pay_at: '',
        token: '',
        company_id: '',
        page: 1,
        type: '',
      },
      pageData: {
        total: 0,
      },
      data: {
        sum: {
          out: 0,
          in: 0
        },
        budgets: [],
      },
    }
  },
  methods: {
    getData () {
      apiGetProjectNumbersCount(this.search).then(res => {
        this.data = res

        let budgetChart = []

        this.data.budgets.forEach((item) => {
          budgetChart.push({
            name: (item.project_budget_name ? item.project_budget_name : '无归属') +
                " " +
                item.total_price + " ("+ (item.total_price / res.sum.out * 100).toFixed(2) +"%)",
            value: item.total_price
          })
        })

        this.initChart('budgetChart', '', budgetChart)
      })
    },
    getCosts() {
      apiGetProjectNumberCosts(this.search).then((res) => {
        this.tableData = res.data
        this.pageData = res.meta
      })
    },
    initChart(id, title, data = []) {
      let myChart = echarts.init(document.getElementById(id));

      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '数据',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      }

      myChart.setOption(option);
    }
  }
}
</script>
